import React, { useState, FormEvent, useEffect } from 'react';
import styles from './Documents.module.css';

import { Button, SelectChangeEvent } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import dayjs, { Dayjs } from 'dayjs';

import Modal from '@mui/material/Modal';
import SubmitPopup from 'project-name/components/CandidateSecion/Global/Popup/SubmitPopup';

import FormField from './GlobalFormsField/FormField';
import FormSelect from './GlobalFormsField/FormSelect';
import FileInput from './GlobalFormsField/FileInput';
import { cities, countries, getStatusByName, handleDownload, union, validatePassportNumber } from 'project-name/util/util';
import apiClient from 'project-name/api/apiClient';
import InprogressIcon from 'project-name/components/CandidateSecion/Global/Icons/InprogressIcon';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'project-name/Redux/Store';
import { setPdfData } from 'project-name/Redux/features/Candidate';
import AutocompleteInput from './GlobalFormsField/AutoCompleteInput';
import { useLocation } from 'react-router-dom';

interface AddressDetailFormProps {
  onSubmit?: (formData: any) => void;
  persona?: string;
  formData?: any;
}

interface CombinedFormData {
  cur_address: string;
  cur_pin: string;
  per_address: string;
  cur_country: string;
  cur_city: string;
  cur_state: string;
  banglore_address: string;
  banglore_address_to_current: string;
  banglore_pin: string;
  banglore_country: string;
  banglore_city: string;
  banglore_state: string;
  is_passport: string;
  passport_number: string;
  passport_issue_date: Dayjs | string;
  passport_exp_date: Dayjs | string;
  passport_issuance_place: string;
  passportImageFile1?: string;
}
interface FileInputData {
  documentsFile: File | null;
}

interface PassportImageFile {
  passportImageFile: File | null;
}
const AddressDetailForm: React.FC<AddressDetailFormProps> = ({ persona, formData: form }) => {
  const filteredStatus = getStatusByName("Address details");
  const isPending = filteredStatus === "Pending";
  const [showModal, setShowModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(() => persona === 'admin' ? false : true);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const location = useLocation();
  const [user_id, setUserId] = useState<number>(() => persona === 'admin' ? location.state.user_id : 0);
  const [formData, setFormData] = useState<CombinedFormData>({
    cur_address: '',
    cur_pin: '',
    per_address: '',
    cur_country: '',
    cur_city: '',
    cur_state: '',
    banglore_address: '',
    banglore_address_to_current: '',
    banglore_pin: '',
    banglore_country: '',
    banglore_city: '',
    banglore_state: '',
    is_passport: '',
    passport_number: '',
    passport_issue_date: '',
    passport_exp_date: '',
    passport_issuance_place: '',
  });

  const [formErrors, setFormErrors] = useState<Partial<CombinedFormData>>({
    cur_address: '',
    cur_pin: '',
    per_address: '',
    cur_country: '',
    cur_city: '',
    cur_state: '',
    banglore_address: '',
    banglore_address_to_current: '',
    banglore_pin: '',
    banglore_country: '',
    banglore_city: '',
    banglore_state: '',
    is_passport: '',
    passport_number: '',
    passport_issue_date: dayjs(),
    passport_exp_date: dayjs(),
    passport_issuance_place: '',
  });
  useEffect(() => {
    console.log('formData:', formErrors);
  }, [formErrors]);

  const dispatch = useDispatch<AppDispatch>();
  let pdfData = useSelector((state: RootState) => state.candidate.pdfData);
  const toggleEditMode = (e: any) => {
    setIsEditMode((prevMode) => !prevMode);
    e.preventDefault();
  };

  useEffect(() => {
    if (persona === 'admin' && form !== undefined) {
      const sanitizedForm = { ...form };
      for (const key in sanitizedForm) {
        if (sanitizedForm[key] === null || sanitizedForm[key] === undefined) {
          sanitizedForm[key] = '';
        }
      }
      setFormData(sanitizedForm);
    }
  }, [persona, form])

  let combinedStatus = false;
  let passStatus = true;
  let icdStatus = true;
  if (persona !== 'admin' || persona == null || persona === undefined) {

    const storedDocTypeIdsWithStatus105 = JSON.parse(localStorage.getItem("docTypeIdsWithStatus105") || '[]');
    const checkIfAnyDocTypeIdsExist = (storedDocTypeIds: number[]): boolean => {
      return storedDocTypeIdsWithStatus105.some((docTypeId: number) => storedDocTypeIds.includes(docTypeId));
    };
    passStatus = checkIfAnyDocTypeIdsExist([307]);
    icdStatus = checkIfAnyDocTypeIdsExist([308]);
    combinedStatus = passStatus || icdStatus;
  }

  // const storedDocTypeIdsWithStatus105 = JSON.parse(localStorage.getItem("docTypeIdsWithStatus105") || '[]');
  // const checkIfAnyDocTypeIdsExist = (storedDocTypeIds: number[]): boolean => {
  //   return storedDocTypeIdsWithStatus105.some(docTypeId => storedDocTypeIds.includes(docTypeId));
  // };
  // const passStatus = checkIfAnyDocTypeIdsExist([307]);
  // const icdStatus = checkIfAnyDocTypeIdsExist([308]);
  // const combinedStatus = passStatus || icdStatus;

  const fetchFiles = async () => {

    try {
      const response = await apiClient.get(`/api/get_files`, {
        withCredentials: true,
      });

      if (response.status !== 200) {
        throw new Error(`Failed to fetch candidate documents: ${response.status}`);
      }
      const data = response.data;
      if (data) {
        pdfData = data;
        // localStorage.setItem("pdfData", JSON.stringify(data));
        dispatch(setPdfData(data));
      }
    } catch (error) {
      console.error('Error fetching documents:', error);
    }
  }

  if (pdfData && Object.keys(pdfData).length === 0) {
    fetchFiles();
  }
  const toggleAccordion = (index: number) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? -1 : index));
  };

  // handle submit function
  const handleSubmit = async (e: FormEvent, formType: string) => {
    if (formType === 'form1') {
      e.preventDefault();
      const formValid = validateForm();
      if (formValid) {
        try {

          const formDataToSend = new FormData();
          // Append adhaarCardFile to formData
          if (fileInputData.documentsFile) {
            formDataToSend.append('documents', fileInputData.documentsFile);
          }
          // Append passportImageFile to formData
          if (passportImageFile.passportImageFile) {
            formDataToSend.append('documents', passportImageFile.passportImageFile);
          }

          // Format the dates to YYYY-MM-DD
          const formattedIssueDate = dayjs(formData.passport_issue_date).format('YYYY-MM-DDTHH:mm:ss');
          const formattedExpnDate = dayjs(formData.passport_exp_date).format('YYYY-MM-DDTHH:mm:ss');

          if (persona === 'admin') {
            formDataToSend.append('type', 'address_details');
            formDataToSend.append('data', JSON.stringify({
              cur_address: formData.cur_address,
              cur_pin: formData.cur_pin,
              per_address: formData.per_address,
              cur_country: formData.cur_country,
              cur_city: formData.cur_city,
              cur_state: formData.cur_state,
              banglore_address: formData.banglore_address,
              banglore_address_to_current: formData.banglore_address_to_current,
              banglore_pin: formData.banglore_pin,
              banglore_country: formData.banglore_country,
              banglore_city: formData.banglore_city,
              banglore_state: formData.banglore_state,
              is_passport: formData.is_passport,
              passport_number: formData.passport_number,
              passport_issue_date: formattedIssueDate,
              passport_exp_date: formattedExpnDate,
              passport_issuance_place: formData.passport_issuance_place,
            }));
            const response = await apiClient.post(
              `/api/admin/${user_id}/update-additional-candidate-details`,
              formDataToSend,
              {
                withCredentials: true,
              }
            );

            if (response.status !== 200) {
              throw new Error(`Failed to submit form: ${response.status} - ${response.statusText}`);
            }

            const data = response.data;
            console.log('Form submitted successfully:', data);
            setIsEditMode((prevMode) => !prevMode);
            return;
          }

          formDataToSend.append(
            'details',
            JSON.stringify({
              cur_address: formData.cur_address,
              cur_pin: formData.cur_pin,
              per_address: formData.per_address,
              cur_country: formData.cur_country,
              cur_city: formData.cur_city,
              cur_state: formData.cur_state,
              banglore_address: formData.banglore_address,
              banglore_address_to_current: formData.banglore_address_to_current,
              banglore_pin: formData.banglore_pin,
              banglore_country: formData.banglore_country,
              banglore_city: formData.banglore_city,
              banglore_state: formData.banglore_state,
              is_passport: formData.is_passport,
              passport_number: formData.passport_number,
              passport_issue_date: formattedIssueDate,
              passport_exp_date: formattedExpnDate,
              passport_issuance_place: formData.passport_issuance_place,
            }),
          );

          const response = await apiClient.post(
            `/api/candidate/upload-address-details`,
            formDataToSend,
            {
              withCredentials: true,
            }
          );

          if (response.status !== 200) {
            throw new Error(`Failed to submit form: ${response.status} - ${response.statusText}`);
          }

          const data = response.data;
          console.log('Form submitted successfully:', data);

          // Simulated fetch request
          await new Promise((resolve) => setTimeout(resolve, 1000));

          // Simulated successful submission
          setShowModal(true);

          // Simulated successful submission
          // setShowModal(true);

          // Reset form data after successful submission
          setFormData({
            cur_address: '',
            cur_pin: '',
            per_address: '',
            cur_country: '',
            cur_city: '',
            cur_state: '',
            banglore_address: '',
            banglore_address_to_current: '',
            banglore_pin: '',
            banglore_country: '',
            banglore_city: '',
            banglore_state: '',
            is_passport: '',
            passport_number: '',
            passport_issue_date: '',
            passport_exp_date: '',
            passport_issuance_place: '',
          });
        } catch (error) {
          if (persona === 'admin') {
            setIsEditMode((prevMode) => !prevMode);
          }
          console.error('Error submitting form:', error);
        }
      } else {
        console.log('Form validation failed');
      }
    }
  };


  const customErrorMessages: { [key in keyof CombinedFormData]?: string } = {
    cur_address: "Please provide a valid current address.",
    cur_pin: "Please provide a valid current pin.",
    per_address: "Please provide a valid permanent address.",
    cur_country: "Please provide a valid current country.",
    cur_city: "Please provide a valid current city.",
    cur_state: "Please provide a valid current state.",
    banglore_address: "Please provide a valid Bangalore address.",
    banglore_address_to_current: "Please specify if Bangalore address is the same as current address.",
    banglore_pin: "Please provide a valid Bangalore pin.",
    banglore_country: "Please provide a valid Bangalore country.",
    banglore_city: "Please provide a valid Bangalore city.",
    banglore_state: "Please provide a valid Bangalore state.",
    is_passport: "Please specify if you have a passport.",
    passport_number: "Please provide a valid passport number.",
    passport_issue_date: "Please provide a valid passport issue date.",
    passport_exp_date: "Please provide a valid passport expiration date.",
    passport_issuance_place: "Please provide a valid passport issuance place.",
  };

  // input handle function
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>,
    newValue?: any
  ) => {
    if (typeof newValue === 'string' && 'target' in event) {
      // Cast event.target to the correct type
      const target = event.target as HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement;
      const name = target.getAttribute('id')?.split('-')[0];
      setFormData((prevData) => {
        const newData = {
          ...prevData,
          [name as string]: newValue, // or `newValue.value` depending on your Autocomplete setup
        };
        console.log(newData); // Logging the updated form data
        return newData;
      });
    }
    else if ('target' in event) {
      const { name, value } = event.target;
      setFormData((prevData) => {
        const newData = {
          ...prevData,
          [name]: value,
        };
        return newData;
      });
    };
  }


  const [fileInputData, setFileInputData] = useState<FileInputData>({
    documentsFile: null,
  });

  const [passportImageFile, setPassportImageFile] = useState<PassportImageFile>({
    passportImageFile: null,
  });

  const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const files = target.files;


    if (files && files.length > 0) {
      const file = files[0];
      const fileExtension = file?.name.split('.').pop()?.toLowerCase();

      if (fileExtension !== 'pdf') {
        alert('Only PDF files are allowed.');
        return;
      }
      // Process the files based on input type
      switch (target.id) {
        case 'documentsInput':
          setFileInputData((prevData) => ({
            ...prevData,
            documentsFile: files[0] || null, // Set to null if no file selected
          }));
          break;
      }
    }
  };

  const handlePassportImageFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const files = target.files;



    if (files && files.length > 0) {
      // const file = files[0];
      // const fileExtension = file?.name.split('.').pop()?.toLowerCase();


      // Process the files based on input type
      switch (target.id) {
        case 'passportImageInput':
          setPassportImageFile((prevData) => ({
            ...prevData,
            passportImageFile: files[0] || null, // Set to null if no file selected
          }));
          break;
      }
    }
  };

  const handleDateChange = (date: Dayjs | null, field: 'passport_issue_date' | 'passport_exp_date') => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: date || dayjs(), // Use current date as default if date is null
    }));
  };

  const handleIssueDateChange = (date: Dayjs | null) => {
    handleDateChange(date, 'passport_issue_date');
  };

  const handleExpDateChange = (date: Dayjs | null) => {
    handleDateChange(date, 'passport_exp_date');
  };

  // form field validate function
  const validateField = (name: keyof CombinedFormData, value: string) => {
    // if (!isNaN(Number(value))) {
    //   return '';
    // }
    if (!value.trim()) {
      return `${name} is required`;
    }
    // Return null if validation passes
    return null;

  };

  // form validation function
  const validateForm = () => {
    const errors: Partial<CombinedFormData> = {};
    let formValid = true;

    (Object.keys(formData) as (keyof CombinedFormData)[]).forEach((field) => {
      if (formData.banglore_address_to_current === 'yes' && ['banglore_address', 'banglore_pin', 'banglore_country', 'banglore_city', 'banglore_state'].includes(field)) {
        return;
      }
      if (formData.is_passport === 'no' && ['passport_number', 'passport_issue_date', 'passport_exp_date', 'passport_issuance_place'].includes(field)) {
        return;
      }
      // Validate passport number if applicable
      if (formData.is_passport === 'yes') {
        const passportNumberError = validatePassportNumber(formData.passport_number);
        if (passportNumberError) {
          errors.passport_number = passportNumberError;
        }
        if (!passportImageFile.passportImageFile) {
          errors.passportImageFile1 = 'Please upload passport image';
          formValid = false;
        }
      }
      const value = formData[field];
      const stringValue = value instanceof dayjs ? value.format('YYYY-MM-DD') : String(value); // Convert Dayjs to string
      const error = validateField(field, stringValue);
      if (error) {
        errors[field] = customErrorMessages[field] || error;
        formValid = false;
      }
    });
   
    // fileInputData.documentsFile

    setFormErrors(errors);
    return formValid;
  };

  // submit popup
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const address = [
    {
      value: 'yes',
      label: 'Yes',
    },
    {
      value: 'no',
      label: 'No',
    },
  ];


  return (
    <section className={styles.mainAccord}>
      {/* Modal */}
      {showModal && (
        <Modal
          open={showModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <SubmitPopup handleClose={handleCloseModal} />
        </Modal>
      )}
      {/* first accordion  */}
      <div className={styles.subAccord}>
        {persona !== 'admin' && (
          <button
            className={`${styles.accordion} ${activeIndex === 0 ? styles.active : ''}`}
            onClick={() => toggleAccordion(0)}
            aria-expanded={activeIndex === 0}
          >
            <p>Address details</p>
            <b className={styles.statusDiv}>
              <InprogressIcon />
              <ExpandMoreIcon
                className={styles.moreIcon}
                style={{ transform: activeIndex === 0 ? 'rotate(180deg)' : 'rotate(0deg)', color: '#fff' }}
              />
            </b>
          </button>
        )

        }
        <form className={styles.panel} style={{ display: activeIndex === 0 || persona == 'admin' ? 'block' : 'none' }}>
          <div className={styles.mAttachDiv}>
            <div className={styles.fieldDiv}>

              <div>
                <div className={` ${combinedStatus ? styles.disabled : ''}`}>

                  <FormField
                    label="House/Flat No. Street/Colony Area"
                    name="cur_address"
                    type='text'
                    value={formData.cur_address}
                    onChange={handleChange}
                    error={formErrors.cur_address}
                    cssInput1="subDiv2"
                    cssInput2="label"
                    cssInput5="descInput"
                    placeholder="Enter Details"
                    isTextarea={true}
                    rows={7}
                    cols={52}
                    readOnly={!isEditMode}
                  />

                  <FormField
                    label="Pin code"
                    name="cur_pin"
                    type='number'
                    value={formData.cur_pin}
                    onChange={handleChange}
                    error={formErrors.cur_pin}
                    cssInput1="subDiv2"
                    cssInput2="label"
                    placeholder="Enter Details"
                    readOnly={!isEditMode}
                  />

                  <FormSelect
                    label="Is your Permanent Address same as Current Address"
                    name="per_address"
                    inputlabel="Select"
                    value={formData.per_address}
                    error={formErrors.per_address}
                    handleChange={handleChange}
                    options={address}
                    selectCss1="subDiv2"
                    selectCss2="label"
                    selectCss3="textField2"
                    readOnly={!isEditMode}
                  />
                </div>
                {formData.banglore_address_to_current === 'no' && (
                  <>

                    <FormField
                      label="House/Flat No. Street/Colony Area"
                      name="banglore_address"
                      value={formData.banglore_address}
                      onChange={handleChange}
                      error={formErrors.banglore_address}
                      cssInput1="subDiv2"
                      cssInput2="label"
                      type='text'
                      cssInput5="descInput"
                      placeholder="Enter Details"
                      isTextarea={true}
                      rows={7}
                      cols={52}
                      readOnly={!isEditMode}
                    />



                    <FormField
                      label="Pin code"
                      name="banglore_pin"
                      type='number'
                      value={formData.banglore_pin}
                      onChange={handleChange}
                      error={formErrors.banglore_pin}
                      cssInput1="subDiv2"
                      cssInput2="label"
                      placeholder="Enter Details"
                      readOnly={!isEditMode}
                    />
                  </>

                )
                }

                <div className={` ${combinedStatus ? styles.disabled : ''}`}>

                  <FormSelect
                    label="Do You Have a Valid Passport?"
                    name="is_passport"
                    inputlabel="Select"
                    value={formData.is_passport}
                    error={formErrors.is_passport}
                    handleChange={handleChange}
                    options={address}
                    selectCss1="subDiv2"
                    selectCss2="label"
                    selectCss3="textField2"
                    readOnly={!isEditMode}
                  />
                </div>

                {formData.is_passport === 'yes' && (
                  <>
                    <FormField
                      label="Passport number"
                      name="passport_number"
                      type='text'
                      value={formData.passport_number}
                      onChange={handleChange}
                      error={formErrors.passport_number}
                      cssInput1="subDiv2"
                      cssInput2="label"
                      placeholder="Enter Details"
                      readOnly={!isEditMode}
                    />

                    <AutocompleteInput
                      label="Place Of Issuance (Country)"
                      name="passport_issuance_place"
                      inputlabel="Select"
                      value={formData.passport_issuance_place}
                      error={formErrors.passport_issuance_place}
                      handleChange={handleChange}
                      options={countries}
                      selectCss1="subDiv2"
                      selectCss2="label"
                      selectCss3="textField2"
                      readOnly={!isEditMode}
                    />

                    <div className={styles.subDiv}>
                      <div>
                        <p className={styles.label}>
                          Date of passport issue<span style={{ color: 'red' }}>*</span>
                        </p>
                        <div className={styles.textField}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <div style={{ backgroundColor: '#fff' }}>
                              <DatePicker
                                label="Select"
                                name="passport_issue_date"
                                value={dayjs(formData.passport_issue_date)}
                                className="custom-date-picker"
                                onChange={handleIssueDateChange}
                                readOnly={!isEditMode}
                              />
                              <p style={{ color: "red", fontSize: "12px", fontWeight: "500" }}>    {String(formErrors.passport_issue_date ?? "")}
                              </p>
                            </div>
                          </LocalizationProvider>
                        </div>
                      </div>
                      <div>
                        <p className={styles.label}>
                          Date of passport expiry<span style={{ color: 'red' }}>*</span>
                        </p>
                        <div className={styles.textField}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <div style={{ backgroundColor: '#fff' }}>
                              <DatePicker
                                label="Select"
                                className="custom-date-picker"
                                name="passport_exp_date"
                                value={dayjs(formData.passport_exp_date)}
                                onChange={handleExpDateChange}
                                readOnly={!isEditMode}
                              />
                              <p style={{ color: "red", fontSize: "12px", fontWeight: "500" }}>{String(formErrors.passport_exp_date ?? "")}</p>
                            </div>
                          </LocalizationProvider>
                        </div>
                      </div>
                    </div>
                    <div className={` ${!passStatus && !isPending ? styles.disabled : ''}`}>

                      <FileInput
                      name='passportImage'
                        label="Upload Passport Image"
                        file={passportImageFile.passportImageFile}
                        onChange={handlePassportImageFileChange}
                        id="passportImageInput"
                        fileCss1="docDiv1"
                        fileCss2="label"
                        fileCss3="fileDiv"
                        fileCss4="fileDiv1"
                        readOnly={!isEditMode}

                      />
                       <p style={{ color: "red", fontSize: "12px", fontWeight: "500" }}>{String(formErrors.passportImageFile1 ?? "")}</p>
                    </div>
                  </>
                )}

                {formData.is_passport === 'no' && (
                  <>
                    <div className='form-header' style={{ marginTop: "20px" }}>
                      <p>Please upload Indian Citizenship form. In case you don't have a current Indian Citizenship Form, then download a template from link below and upload the same after filling the details.</p>
                      <div className={styles.btnDivack}>
                        <Button variant="contained" endIcon={<CloudDownloadIcon />} className={styles.btn} onClick={() => handleDownload('citizen', pdfData?.ICD?.["Indian Citizen Declaration.pdf"] ?? '')}>
                          Download Indian Citizenship <br />Declaration Form
                        </Button>
                      </div>
                    </div>
                    <div className={` ${!icdStatus && !isPending ? styles.disabled : ''}`}>
                      <FileInput
                        label="Upload Indian Citizenship"
                        file={fileInputData.documentsFile}
                        onChange={handleFileInputChange}
                        id="documentsInput"
                        fileCss1="docDiv1"
                        fileCss2="label"
                        fileCss3="fileDiv"
                        fileCss4="fileDiv1"
                        readOnly={!isEditMode}
                      />
                       <p style={{ color: "red", fontSize: "12px", fontWeight: "500" }}>{String(formErrors.passportImageFile1 ?? "")}</p>
                      
                    </div>
                  </>

                )}



              </div>

              <div className={` ${combinedStatus ? styles.disabled : ''}`}>
                <AutocompleteInput
                  label="Country"
                  name="cur_country"
                  inputlabel="Select"
                  value={formData.cur_country}
                  error={formErrors.cur_country}
                  handleChange={handleChange}
                  options={countries}
                  selectCss1="subDiv2"
                  selectCss2="label"
                  selectCss3="textField2"
                  readOnly={!isEditMode}
                />

                {
                  formData.cur_country !== 'India' ? (
                    <FormField
                      label="State/Union Territory"
                      name="cur_state"
                      type='text'
                      value={formData.cur_state}
                      onChange={handleChange}
                      error={formErrors.cur_state}
                      cssInput1="subDiv2"
                      cssInput2="label"
                      placeholder="Enter Details"
                      readOnly={!isEditMode}
                    />
                  ) : (
                    <AutocompleteInput
                      label="State/Union Territory"
                      name="cur_state"
                      inputlabel="Select"
                      value={formData.cur_state}
                      error={formErrors.cur_state}
                      handleChange={handleChange}
                      options={union}
                      selectCss1="subDiv2"
                      selectCss2="label"
                      readOnly={!isEditMode}
                      selectCss3="textField2"
                    />
                  )

                }
                {formData.cur_country !== 'India' ? (
                  <FormField
                    label="City"
                    name="cur_city"
                    type='text'
                    value={formData.cur_city}
                    onChange={handleChange}
                    error={formErrors.cur_city}
                    cssInput1="subDiv2"
                    cssInput2="label"
                    readOnly={!isEditMode}
                    placeholder="Enter Details"
                  />
                ) : (
                  <AutocompleteInput
                    label="City"
                    name="cur_city"
                    inputlabel="Select"
                    value={formData.cur_city}
                    error={formErrors.cur_city}
                    handleChange={handleChange}
                    options={cities.find((state) => state.state_name === formData.cur_state)?.city_names || []}
                    selectCss1="subDiv2"
                    selectCss2="label"
                    selectCss3="textField2"
                    readOnly={!isEditMode}
                  />
                )
                }


                <FormSelect
                  label="Is your Bangalore Address same as Current Address?"
                  name="banglore_address_to_current"
                  inputlabel="Select"
                  value={formData.banglore_address_to_current}
                  error={formErrors.banglore_address_to_current}
                  handleChange={handleChange}
                  options={address}
                  selectCss1="subDiv2"
                  selectCss2="label"
                  selectCss3="textField2"
                  readOnly={!isEditMode}
                />

                {formData.banglore_address_to_current === 'no' && (
                  <>
                    <AutocompleteInput
                      label="Country"
                      name="banglore_country"
                      inputlabel="Select"
                      value={formData.banglore_country}
                      error={formErrors.banglore_country}
                      handleChange={handleChange}
                      options={countries}
                      selectCss1="subDiv2"
                      selectCss2="label"
                      selectCss3="textField2"
                      readOnly={!isEditMode}
                    />
                    {formData.banglore_country !== 'India' ? (
                      <FormField
                        label="City"
                        name="banglore_city"
                        type='text'
                        value={formData.banglore_city}
                        onChange={handleChange}
                        error={formErrors.banglore_city}
                        cssInput1="subDiv2"
                        cssInput2="label"
                        placeholder="Enter Details"
                        readOnly={!isEditMode}
                      />
                    ) : (
                      <AutocompleteInput
                        label="City"
                        name="banglore_city"
                        inputlabel="Select"
                        value={formData.banglore_city}
                        error={formErrors.banglore_city}
                        handleChange={handleChange}
                        options={cities.find((state) => state.state_name === formData.banglore_state)?.city_names || []}
                        selectCss1="subDiv2"
                        selectCss2="label"
                        selectCss3="textField2"
                        readOnly={!isEditMode}
                      />
                    )
                    }

                    {
                      formData.cur_country !== 'India' ? (
                        <FormField
                          label="State/Union Territory"
                          name="banglore_state"
                          type='text'
                          value={formData.banglore_state}
                          onChange={handleChange}
                          error={formErrors.banglore_state}
                          cssInput1="subDiv2"
                          cssInput2="label"
                          placeholder="Enter Details"
                          readOnly={!isEditMode}
                        />
                      ) : (
                        <AutocompleteInput
                          label="State/Union Territory"
                          name="banglore_state"
                          inputlabel="Select"
                          value={formData.banglore_state}
                          error={formErrors.banglore_state}
                          handleChange={handleChange}
                          options={union}
                          selectCss1="subDiv2"
                          selectCss2="label"
                          selectCss3="textField2"
                          readOnly={!isEditMode}
                        />
                      )

                    }

                  </>
                )}



              </div>
            </div>
            {
              persona !== 'admin' && (
                <div className={styles.btnDiv}>
                  <div></div>
                  <div className={styles.subbtnDiv}>
                    <div className={styles.btn}>
                      <Button type="submit" variant="contained" color="primary" onClick={(e) => handleSubmit(e, 'form1')}>
                        Submit document
                      </Button>
                    </div>

                  </div>
                </div>
              )
            }

            {
              persona === 'admin' && (
                <div className={styles.btnDiv2}>
                  <div></div>
                  <div className={styles.subbtnDiv1} >
                    <div className={styles.btn} style={{ display: "flex", gap: "15px" }}>
                      <Button type="submit" variant="contained" color="primary" onClick={(e) => handleSubmit(e, 'form1')}>
                        Update document
                      </Button>
                      {!isEditMode && (
                        <Button type="submit" variant="contained" color="primary" onClick={toggleEditMode}>
                          Edit document
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              )
            }

          </div>
        </form>
      </div>
    </section>
  );
};

export default AddressDetailForm;

import React, { useState } from 'react';
import { Grid, Card, CardMedia, CardContent, Typography, Box } from '@mui/material';
import VerticalNavbar from 'project-name/components/CandidateSecion/Global/Navbar/VerticalNavbar';
import cardImage1 from 'project-name/assets/img/cardImage1.png';
import styles from 'project-name/pages/CandidatePages/about.module.css';
import ImageSlider from 'project-name/components/AboutSlider';
import mainstyles from '../.././css/layout.module.css';
import image1 from '../../assets/img/cardImage1.png';
import image2 from '../../assets/img/muser.png';
import Breadcrumb from 'project-name/components/CandidateSecion/Documents/Breadcrumb';


interface Image {
    src: string;
}

interface Topic {
    id: number;
    title: string;
    images: Image[];
}

const Gallery: React.FC = () => {
    
    const [selectedTopic, setSelectedTopic] = useState<Topic | null>(null);
    const [isSliderOpen, setIsSliderOpen] = useState(false);

    const topics: Topic[] = [
        { id: 1, title: 'Topic 1', images: [{ src: image1 }, { src: image2 }, { src: image1 }] },
        { id: 2, title: 'Topic 2', images: [{ src: image1 }, { src: image2 }, { src: image1 }] },
        { id: 3, title: 'Topic 3', images: [{ src: image1 }, { src: image2 }, { src: image1 }] },
        { id: 4, title: 'Topic 4', images: [{ src: image1 }, { src: image2 }, { src: image1 }] },
        { id: 5, title: 'Topic 5', images: [{ src: image1 }, { src: image2 }, { src: image1 }] },
        { id: 6, title: 'Topic 6', images: [{ src: image1 }, { src: image2 }, { src: image1 }] },
        { id: 7, title: 'Topic 7', images: [{ src: image1 }, { src: image2 }, { src: image1 }] },
    ];

    const handleTopicClick = (topicId: number) => {
        console.log('Topic clicked:', topicId);
        const topic = topics.find((t) => t.id === topicId);
        if (topic) {
            setSelectedTopic(topic);
            setIsSliderOpen(true);  // Open the slider
            document.body.style.overflow = 'visible'; 
            document.body.classList.add('overflowVisible');
        }
    };

    const handleCloseSlider = () => {
        setIsSliderOpen(false);  // Close the modal
        document.body.classList.remove('overflowVisible');
    };

    return (
        <Box className={mainstyles.mDiv}>
            <Box>
                <VerticalNavbar />
            </Box>

            <Box sx={{ margin: '40px 100px', minHeight: "100vh" }}>
                <div style={{ margin: '40px 0px' }}>
                    <Breadcrumb bread1="Home" bread2="Gallery" link1="/" />
                </div>
                <Typography variant="h6" sx={{ textAlign: "center", fontWeight: "500", mb: "20px" }} >

                    Gallery
                </Typography>
                <Typography variant="h6" sx={{ textAlign: "", fontWeight: "500", mb: "2px", fontSize: "14px" }} >

                    Events
                </Typography>



                <Grid container spacing={2}>
                    {/* First Card */}
                    <Grid item md={6}>

                        <Card
                            sx={{ position: 'relative', borderRadius: '10px' }}
                            // For Topic 1
                            onClick={() => handleTopicClick(1)} className={styles.biggerImage}
                        >
                            <CardMedia component="img" height="300" image={cardImage1} alt="Event Image 1" />
                            <CardContent
                                sx={{
                                    position: 'absolute',
                                    bottom: 0,
                                    left: 0,
                                    color: 'white',
                                    width: '100%',
                                }}
                            >
                                <Typography variant="h5">Lorem Ipsum</Typography>
                                <Typography variant="subtitle1">2024</Typography>
                            </CardContent>
                        </Card>
                    </Grid>


                    <Grid item md={6}>
                        <Grid container spacing={2}>
                            <Grid item md={6}>
                                <Card
                                    sx={{ position: 'relative', borderRadius: '10px' }}
                                    onClick={() => handleTopicClick(2)}
                                    className={styles.smallerImage}
                                >
                                    <CardMedia component="img" height="300" image={cardImage1} alt="Event Image 2" />
                                    <CardContent
                                        sx={{
                                            position: 'absolute',
                                            bottom: 0,
                                            left: 0,
                                            color: 'white',
                                            width: '100%',
                                        }}
                                    >
                                        <Typography variant="h5">Lorem Ipsum</Typography>
                                        <Typography variant="subtitle1">2024</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>

                            {/* Third Card */}
                            <Grid item md={6}>
                                <Card
                                    sx={{ position: 'relative', borderRadius: '10px' }}
                                    onClick={() => handleTopicClick(3)}
                                    className={styles.smallerImage}
                                >
                                    <CardMedia component="img" height="300" image={cardImage1} alt="Event Image 3" />
                                    <CardContent
                                        sx={{
                                            position: 'absolute',
                                            bottom: 0,
                                            left: 0,
                                            color: 'white',
                                            width: '100%',
                                        }}
                                    >
                                        <Typography variant="h5">Lorem Ipsum</Typography>
                                        <Typography variant="subtitle1">2024</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>

                            {/* Fourth Card */}
                            <Grid item md={6}>
                                <Card
                                    sx={{ position: 'relative', borderRadius: '10px' }}
                                    onClick={() => handleTopicClick(4)}  // For Topic 4
                                    className={styles.smallerImage}
                                >
                                    <CardMedia component="img" height="300" image={cardImage1} alt="Event Image 4" />
                                    <CardContent
                                        sx={{
                                            position: 'absolute',
                                            bottom: 0,
                                            left: 0,
                                            color: 'white',
                                            width: '100%',
                                        }}
                                    >
                                        <Typography variant="h5">Lorem Ipsum</Typography>
                                        <Typography variant="subtitle1">2024</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>

                            {/* Fifth Card */}
                            <Grid item md={6}>
                                <Card
                                    sx={{ position: 'relative', borderRadius: '10px' }}
                                    onClick={() => handleTopicClick(5)}  // For Topic 5
                                    className={styles.smallerImage}
                                >
                                    <CardMedia component="img" height="300" image={cardImage1} alt="Event Image 5" />
                                    <CardContent
                                        sx={{
                                            position: 'absolute',
                                            bottom: 0,
                                            left: 0,
                                            color: 'white',
                                            width: '100%',
                                        }}
                                    >
                                        <Typography variant="h5">Lorem Ipsum</Typography>
                                        <Typography variant="subtitle1">2024</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* Sixth Card */}
                    <Grid item md={9}>
                        <Card
                            sx={{ position: 'relative', borderRadius: '10px' }}
                            onClick={() => handleTopicClick(6)}  // For Topic 6
                            className={styles.smallerImage}
                        >
                            <CardMedia component="img" height="300" image={cardImage1} alt="Event Image 6" />
                            <CardContent
                                sx={{
                                    position: 'absolute',
                                    bottom: 0,
                                    left: 0,
                                    color: 'white',
                                    width: '100%',
                                }}
                            >
                                <Typography variant="h5">Lorem Ipsum</Typography>
                                <Typography variant="subtitle1">2024</Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Seventh Card */}
                    <Grid item md={3}>
                        <Card
                            sx={{ position: 'relative', borderRadius: '10px' }}
                            onClick={() => handleTopicClick(7)}  // For Topic 7
                            className={styles.smallerImage}
                        >
                            <CardMedia component="img" height="300" image={cardImage1} alt="Event Image 7" />
                            <CardContent
                                sx={{
                                    position: 'absolute',
                                    bottom: 0,
                                    left: 0,
                                    color: 'white',
                                    width: '100%',
                                }}
                            >
                                <Typography variant="h5">Lorem Ipsum</Typography>
                                <Typography variant="subtitle1">2024</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

                {/* Slider for Selected Topic */}
                {selectedTopic && (
                    <ImageSlider
                        images={selectedTopic.images.map((image, index) => ({ ...image, key: index }))}
                        open={isSliderOpen}
                        onClose={handleCloseSlider}
                    />
                )}
            </Box>
        </Box>
    );
};

export default Gallery;

import styles from './FaqSection.module.css';
import { Link } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import SearchBar from './SearchBar';

import Accordion from './Accordion';




import faq1Img from 'project-name/assets/img/faq/faq1.png'

interface AccordionData {
  title: string;
  content: string;
  imagePath: string;
}

const accordionData: AccordionData[] = [
  { 
    title: "Is there a cab facility available? ", 
    content: "Yes, employees residing within the BBMP limits are eligible for the office cab facility.", 
    imagePath: "" 
  },
  { 
    title: "What is the dress code at the office? ", 
    content: "We follow a business casual dress code. " ,
    imagePath: faq1Img
  },
  { 
    title: "Where can I park my vehicle? ", 
    content: "Employees can park their vehicles in Basement 2 of the building." ,
    imagePath: faq1Img
  },
  { 
    title: "Will I receive a joining kit? ", 
    content: "Yes, all new joiners receive a welcome hamper within the first two days of joining.  " ,
    imagePath: faq1Img
  },
  { 
    title: "What is my office location? ", 
    content: "Depending on your team, you will be assigned to either our GCC or GAC office in Bangalore. Specific details will be provided during your onboarding.  " ,
    
    imagePath: faq1Img
  },
  { 
    title: "When will I receive my first salary? ", 
    content: " If you join before the 15th of the month, your salary will be credited on the second last working day of that month. If you join after the 15th, your first salary, covering two months, will be credited on the second last working day of the following month. " ,
    imagePath: faq1Img
  },
  { 
    title: " What happens if my background verification is not cleared?  ", 
    content: " Our background verification (BGV) process is stringent. In case of any discrepancies, the Talent Acquisition team will assess the situation and take appropriate action based on the severity. " ,
    imagePath: faq1Img
  },
  { 
    title: "Will I be provided with a laptop? ", 
    content: "Full-time employees are provided with a company laptop. Interns and contractual employees are expected to bring their own laptops.  " ,
    imagePath: faq1Img
  },
  { 
    title: "Are employees eligible for free beer?  ", 
    content: " Full-time employees receive beer coupons worth ₹1200 per month, redeemable at partner stores. This is a part of our Flex benefits at AB InBev. If you prefer not to avail this benefit, you can exchange it for an alternative benefit, details of which will be shared during the induction. " ,
    imagePath: faq1Img
  },
  { 
    title: "Is attendance at the office required everyday? ", 
    content: " Currently, we follow a hybrid work model with two days in the office each week. Your specific in-office days will be assigned based on your team and will change monthly." 
  ,
    imagePath: faq1Img
  },
 
];
const FaqSection = () => {
  return (

    <div className={styles.mainDiv}>
    <div className={styles.faqContainer2}>
      <div className={styles.faqContainer1}>
        <div className={styles.faqTopbar}>
          <Link to={'/#'} className={styles.chevIcon}>
            <ChevronLeftIcon style={{color: '#000'}} />
          </Link>
          <h3>Frequently asked questions</h3>
        </div>
      </div>
      
        {/* <SearchBar/> */}
    </div>
  
    {accordionData.map((accordion, index) => (
        <Accordion key={index} title={accordion.title}>
          {accordion.content && <p dangerouslySetInnerHTML={{ __html: accordion.content }}></p>}
          {accordion.imagePath && <img src={accordion.imagePath} className={styles.imgDiv} alt="Image for Accordion" />}
        </Accordion>
      ))}
    </div>
  );
};

export default FaqSection;

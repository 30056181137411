import React, { useState, useEffect } from 'react';
import { Box, IconButton, Card, CardMedia, Modal } from '@mui/material';
import { ArrowBack, ArrowForward, Close } from '@mui/icons-material';
import mainstyles from '../css/layout.module.css';
import VerticalNavbar from 'project-name/components/CandidateSecion/Global/Navbar/VerticalNavbar';

interface Image {
    src: string;
}

interface ImageSliderProps {
    images: Image[];
    open: boolean;
    onClose: () => void;
}

const ImageSlider: React.FC<ImageSliderProps> = ({ images, open, onClose }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    // Log currentIndex to see if it's updating correctly
    useEffect(() => {
        console.log('Current Index:', currentIndex);
    }, [currentIndex]);

    const handleNext = () => {
        console.log('Next button clicked');
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const handlePrev = () => {
        console.log('Prev button clicked');
        setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    return (
        <>
            <Box
            >
                {/* <Box  sx={{  position: 'relative' }}>
        <VerticalNavbar />
      </Box> */}
                <Box sx={{ margin: '40px 100px', zIndex: 1, backgroundColor: "transparent" }}>
                    <Modal
                        open={open}
                        onClose={onClose}
                        BackdropProps={{ style: { background: 'transparent', outline: "none", boxShadow: "none", border: "none", } }}
                        sx={{
                            display: 'flex', alignItems: 'center',
                            backgroundImage: 'url(src/assets/img/bg/mainbg.png)',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            height: '100vh',
                            zIndex: 999,
                            top: '232px',
                            position: "absolute",
                            left: "20%",
                            backgroundColor: "transparent",
                            border: "none",
                            boxShadow: "none",
                            '&:focus': {
                                outline: 'none', // Remove the focus ring
                            },

                            // Ensures the background covers the full vieport
                        }}
                    >
                        <Box sx={{
                            position: 'relative', width: '80%', maxWidth: '600px', bgcolor: 'transparent', mx: "auto", border: "none", boxShadow: "none", p: 2, top: '-120px', '&.Mui-focusVisible': {
                                outline: 'none',
                            }
                        }}>
                            <IconButton
                                sx={{ position: 'absolute', top: '35px', right: '35px', zIndex: 10, color: 'white', border: "1px solid white", p: 0.2, boxShadow: "none" }}
                                onClick={onClose}
                            >
                                <Close sx={{ fontSize: "10px", }} />
                            </IconButton>
                            {/* Slider Controls */}
                            <IconButton
                                sx={{ position: 'absolute', top: '50%', left: '-50px', zIndex: 10, color: 'white', transform: 'translateY(-50%)', borderRadius: "5px", p: "2px", backgroundColor: "black" }}
                                onClick={handlePrev}
                            >
                                <ArrowBack fontSize="large" />
                            </IconButton>
                            <IconButton
                                sx={{
                                    position: 'absolute', top: '50%', right: '-65px', zIndex: 10, color: 'white', transform: 'translateY(-50%)', borderRadius: "5px", p: "2px", backgroundColor: "black", '&:hover': {
                                        backgroundColor: 'gray' // Change background color on hover
                                    }
                                }}
                                onClick={handleNext}
                            >
                                <ArrowForward sx={{ fontSize: "35px" }} />
                            </IconButton>

                            {/* Image Display */}
                            <Card sx={{ margin: '10px', textAlign: 'center' }}>
                                {images.length > 0 && (
                                    <>
                                        <CardMedia
                                            component="img"
                                            height="300"
                                            image={images?.[currentIndex]?.src || ''}
                                            alt={`Slide ${currentIndex}`}

                                            sx={{
                                                borderRadius: 'none',
                                                backgroundColor: 'transparent',
                                                boxShadow: 'none',
                                                outline: "none",
                                                '&:focus': {
                                                    outline: 'none', // Remove the focus ring
                                                },
                                            }}
                                        />
                                        {/* Debugging Current Image */}

                                    </>
                                )}
                            </Card>
                        </Box>
                    </Modal>
                </Box>
            </Box>
        </>
    );
};

export default ImageSlider;

import React, { useState } from 'react';
import mainstyles from '../../../css/layout.module.css';
import VerticalNavbar from '../Global/Navbar/VerticalNavbar';
import styles from './ContactSection.module.css';
import { Toast } from 'react-toastify/dist/components';
import { Button, colors } from '@mui/material';
import PhoneMenu from '../Global/Navbar/phoneMenu';
import Breadcrumb from '../Documents/Breadcrumb';
import FormField from '../Documents/CandidateForms/DocumentsUpload/GlobalFormsField/FormField';
import FileInput from '../Documents/CandidateForms/DocumentsUpload/GlobalFormsField/FileInput';
import apiClient from 'project-name/api/apiClient';
import { useSelector } from 'react-redux';
import { RootState } from 'project-name/Redux/Store';
import QueryPopup from '../Global/Popup/Query Popup';
import { toast } from 'react-toastify';

interface attachmentFile {
  attachmentFile: File | null;
}

const ContactSection: React.FC = () => {
  const [attachmentFile, setattachmentFile] = useState<attachmentFile>({
    attachmentFile: null,
  });
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [errors, setErrors] = useState({
    topic: '',
    name: '',
    email: '',
    contact: '',
    description: '',
    attachmentFile: '',
  });

  const handleClose = () => {
    setIsPopupOpen(false);
  };

  let candidateDetails = useSelector((state: RootState) => state.candidate.candidate);
  let personal_details = useSelector((state: RootState) => state.candidate.personalDetails);

  if (!candidateDetails || !personal_details) {
    candidateDetails = JSON.parse(localStorage.getItem("candidateData") || "");
    personal_details = JSON.parse(localStorage.getItem("personalDetails") || "");
  }

  const [TopicformData, setFormData] = useState({
    topic: '',
    name: candidateDetails?.full_name,
    email: candidateDetails?.email,
    contact: personal_details?.phone_no,
    description: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleattachmentFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const files = target.files;

    if (files && files.length > 0) {
      const file = files[0];
      const fileExtension = file?.name.split('.').pop()?.toLowerCase();

      if (fileExtension !== 'pdf') {
        alert('Only PDF files are allowed.');
        return;
      }
      setattachmentFile((prevData) => ({
        ...prevData,
        attachmentFile: files[0] || null,
      }));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Validation
    const newErrors = {
      topic: TopicformData.topic ? '' : 'Topic is required',
      name: TopicformData.name ? '' : 'Name is required',
      email: TopicformData.email ? '' : 'Email is required',
      contact: TopicformData.contact ? '' : 'Contact number is required',
      description: TopicformData.description ? '' : 'Description is required',
      attachmentFile: attachmentFile.attachmentFile ? '' : 'Attachment file is required',
    };

    setErrors(newErrors);

    const isValid = Object.values(newErrors).every(error => error === '');

    if (!isValid) {
      return;
    }

    const formData = new FormData();
    formData.append('topic', TopicformData.topic);
    formData.append('name', TopicformData.name || '');
    formData.append('personal_email', TopicformData.email || '');
    formData.append('contact_number', TopicformData.contact);
    formData.append('description', TopicformData.description);
    formData.append('document', attachmentFile.attachmentFile || '');
    try {
      const response = await apiClient.post(
        `/api/candidate/contact_us`,
        formData,
        {
          withCredentials: true,
        }
      );

      setIsPopupOpen(true);
      if (response.status !== 200) {
        toast.error('Failed to send query');
        throw new Error(`Failed to submit form: ${response.status} - ${response.statusText}`);
      }
    } catch (error) {
      toast.error('Failed to send query');
      
      // Optionally, you can set an error state to display an error message to the user
    }
  };

  const handleClear = () => {
    setFormData({
      topic: '',
      name: '',
      email: '',
      contact: '',
      description: '',
    });
    setErrors({
      topic: '',
      name: '',
      email: '',
      contact: '',
      description: '',
      attachmentFile: '',
    });
  };

  return (
    <div className={mainstyles.mDiv}>
      <div>
        <VerticalNavbar />
      </div>

      <div>
        <div style={{ margin: '40px 100px' }}>
          <Breadcrumb bread1="Home" bread2="Contact us" link1="/" />
        </div>

        <form onSubmit={handleSubmit}>
          <div className={styles.formDiv}>
            <div className={styles.msgdiv}>
              <h2>Get in touch with us</h2>
              <p>Describe your problem so that our team can help you out better.</p>
            </div>

            <div className={styles.pdi}>
              <div>

                <FormField label="Topic" name="topic" type="" value={TopicformData.topic} onChange={handleChange} cssInput1="subDiv2" placeholder='Enter topic'
                  cssInput2="label" />
                {errors.topic && <p style={{ color: "red", fontSize: "12px", fontWeight: "500" }}>{errors.topic}</p>}
              </div>
              <div>

                <FormField label="Your name" name="name" type="" value={TopicformData.name} onChange={handleChange} cssInput1="subDiv2" placeholder='Enter your name'
                  cssInput2="label" />
                {errors.name && <p style={{ color: "red", fontSize: "12px", fontWeight: "500" }}>{errors.name}</p>}
              </div>

            </div>

            <div className={styles.pdi}>
              <div >

                <FormField label="Personal Email" name="email" type="" value={TopicformData.email} onChange={handleChange} cssInput1="subDiv2" placeholder='Enter your email'
                  cssInput2="label" />
                {errors.email && <p style={{ color: "red", fontSize: "12px", fontWeight: "500" }}>{errors.email}</p>}
              </div >
              <div >

                <FormField label="Contact number"  name="contact" type="phone" value={TopicformData.contact} onChange={handleChange} cssInput1="subDiv2" placeholder='Enter your contact number'
                  cssInput2="label" />
                {errors.contact && <p style={{ color: "red", fontSize: "12px", fontWeight: "500" }}>{errors.contact}</p>}
              </div>

            </div>
            <div className={styles.pdi}>
              <div>

                <textarea id="textarea" name="description" rows={10} cols={40} placeholder="Add description here" className={styles.desc} value={TopicformData.description} onChange={handleChange}></textarea>
                {errors.description && <p style={{ color: "red", fontSize: "12px", fontWeight: "500" }}>{errors.description}</p>}
              </div>
              <div >
                <div className={styles.file}>
                  <FileInput
                    name=''
                    label="Upload Attachments if any"
                    file={attachmentFile.attachmentFile}
                    onChange={handleattachmentFileChange}
                    id="passportImageInput"
                    fileCss1="docDiv1"
                    fileCss2="label"
                    fileCss3="fileDiv"
                    fileCss4="fileDiv1"
                  />
                </div>
                {errors.attachmentFile && <p style={{ color: "red", fontSize: "12px", fontWeight: "500" }}>{errors.attachmentFile}</p>}
              </div>

            </div>
          </div>

          <div className={styles.btnDiv}>

            <div className={styles.subbtnDiv}>
              <div className={styles.btn}>
                <Button type="submit" variant="contained" color="primary">
                  Send query
                </Button>
              </div>

              <div className={styles.btn}>
                <Button variant="outlined" onClick={handleClear}>
                  Clear field
                </Button>
              </div>
            </div>

            {isPopupOpen && (
              <QueryPopup handleClose={handleClose} />
            )}
          </div>
        </form>
      </div >
      <PhoneMenu />
    </div >
  );
};

export default ContactSection;
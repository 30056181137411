import React from 'react';
import { TextField, SxProps } from '@mui/material';
import styles from '../Documents.module.css'; // Ensure your CSS module path is correct
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

interface FormFieldProps {
  label?: string;
  name?: string;
  value?: string;
  onChange?: (event: any) => void;  // Adjusted for PhoneInput compatibility
  error?: string | undefined;
  cssInput1?: keyof typeof styles; 
  cssInput2?: keyof typeof styles; 
  cssInput3?: keyof typeof styles; 
  cssInput4?: keyof typeof styles; 
  cssInput5?: keyof typeof styles; 
  rows?: number;
  cols?: number;
  isTextarea?: boolean;
  placeholder?: string;
  id?: string;
  type: string;
  sx?: SxProps;
  readOnly?: boolean;
  required?: boolean;
}

const FormField: React.FC<FormFieldProps> = ({
  label,
  name,
  value,
  onChange,
  error,
  cssInput1,
  cssInput2,
  cssInput3,
  cssInput4,
  cssInput5,
  rows,
  cols,
  required = false ,
  isTextarea = false,
  placeholder,
  type,
  readOnly,
  sx,
}) => {
  return (
    <div className={cssInput1 ? styles[cssInput1] : styles.cssInput1}>
      <p className={cssInput2 ? styles[cssInput2] : styles.cssInput2} style={{ paddingBottom: '10px' }}>
        {label} {required && <span style={{ color: 'red' }}>*</span>} 
      </p>
      <div className={cssInput3 ? styles[cssInput3] : styles.cssInput3}>
        {type === 'phone' ? (
         <PhoneInput
         country={'us'}  // Set a default country
         value={value}
         onChange={(phone) => {
           if (onChange) {
             onChange({ target: { name, value: phone } });  // Safely call onChange if it's defined
           }
         }}
         inputProps={{
           name,
          
           readOnly,
           id: `${name}-label`,
         }}
         placeholder={placeholder}
         containerClass={cssInput5 ? styles[cssInput5] : styles.cssInput5}
         inputStyle={{ width: '100%' }}
         disabled={readOnly}
       />
        ) : isTextarea ? (
          <TextField
            placeholder={placeholder}
            rows={rows}
            multiline
            className={cssInput5 ? styles[cssInput5] : styles.cssInput5}
            name={name}
            value={value}
            onChange={onChange}
            aria-labelledby={label}
            id={`${name}-label`}
            required={required}
            sx={{ ...sx, padding: "10px 45px", borderColor: 'red', borderRadius: '8px' }}
            disabled={readOnly}
          />
        ) : (
          <TextField
            fullWidth
            placeholder={placeholder}
            variant="outlined"
            name={name}
            type={type}
            value={value}
            onChange={onChange}
            disabled={readOnly}
            className={cssInput4 ? styles[cssInput4] : styles.cssInput4 }
            sx={{ 
              ...sx,
              backgroundColor: "white",
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'EBEBEB',
                  borderRadius: '4px',
                },
                '&:hover fieldset': {
                  borderColor: '',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '',
                },
              },
            }}
            aria-labelledby={`${name}-label`}
            id={`${name}-label`}
            required={required}
          />
        )}
        {error && <div style={{ color: "red", fontSize: "12px", fontWeight: "500" }}>{error}</div>}
      </div>
    </div>
  );
};

export default FormField;
